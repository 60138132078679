import React, { useState } from "react";
import "../../style.css";
import MistPlayer from "./MistPlayer";
import CanvasPlayer from "./CanvasPlayer";

const Player = (props) => {
  // Use the canvas player or Mist player
  const [useMistPlayer, setMistPlayer] = useState(true);
  if (!props.host || props.host == "") {
    return;
  }

  // Display which player is loaded in the header
  let titleString = "Player";
  if (!props.hidePlayer) {
    titleString += useMistPlayer ? " (MistMetaPlayer)" : " (HTML5 Canvas)";
  }

  return (
    <div className="row-container">
      <div className="menu darkBg backgroundBorderBot">
        <div className="column-container-min">
          <p style={{ marginLeft: "1em" }}>{titleString}</p>
        </div>
        <div className="column-container-min" style={{ height: "100%" }}>
          <button
            className="action-button flex-item flex-grow noborder"
            style={{ height: "100%", aspectRatio: "1" }}
            onClick={() => {
              setMistPlayer(!useMistPlayer);
            }}
          >
            <h4 className="nopad">Switch Player</h4>
          </button>
        </div>
      </div>
      {useMistPlayer ? (
        <MistPlayer
          baseUri={"https://" + props.host + props.path}
          streamName={props.streamName}
        />
      ) : (
        <CanvasPlayer
          uri={
            "wss://" + props.host + props.path + "webrtc/" + props.streamName
          }
        />
      )}
    </div>
  );
};

export default Player;
