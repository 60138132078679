/*

View only page. Simply loads a player as long as the source is active

*/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Player from "../components/Players/Player";
import useLoadBalancer from "../hooks/MistLoadBalancer";
import "../style.css";

// Hardcoded stream name as configured in MistServer
// used as the base for wildcard streams
const baseStream = "live";
const View = () => {
  const [bestHost, setHost] = useState("");
  // Get stream id from url
  let { streamid } = useParams();
  const streamName = baseStream + "+" + streamid;
  // Get edge node to play from
  const [getNode] = useLoadBalancer({
    streamName,
  });

  useEffect(() => {
    const getHost = async () => {
      const newHost = await getNode();
      if (newHost == "") {
        console.log("Finding new ingest point in 5 seconds...");
        setInterval(getHost, 5000);
        return;
      }
      if (newHost != bestHost) {
        console.log("Found ingest point " + newHost);
        setHost(newHost);
      }
    };
    getHost();
  }, []);
  // Render player element
  return (
    <Player
      streamName={streamName}
      host={bestHost}
      path={"/view/"}
      link={
        `${window.location.protocol}//${window.location.host}` +
        "/view/" +
        streamid
      }
    />
  );
};

export default View;
