import React, { useEffect } from "react";
import "../../style.css";

/*
  WebRTC Canvas Player

  @param props (object)
  - baseUri (string):           Entire base uri, including any subpath in case a reverse proxy is used
  - streamName (string):        Name of the stream we want to display
 */
const MistPlayer = (props) => {
  // const mistPlayerRef = useRef(null);
  const htmlUri =
    props.baseUri + encodeURIComponent(props.streamName) + ".html";
  const playerUri = props.baseUri + "player.js";

  // Embed as an iframe on localhost
  let useIFrame = false;
  if (window.location.protocol == "http:") {
    useIFrame = true;
  }

  // Else embed properly on first render
  useEffect(() => {
    if (useIFrame) {
      return;
    }

    function playStream() {
      if (!window.mistplayers) return;
      // Try to start it on the non-transcoded track and using WebRTC
      window.mistPlay(props.streamName, {
        target: document.getElementById("mistplayer"),
        poster: "/android-chrome-512x512.png",
        autoplay: true,
        urlappend: "?video=first",
        ABR_resize: false,
        forcePriority: {
          source: [
            [
              "type",
              [
                "webrtc",
                "html5/video/webm",
                "ws/video/mp4",
                "html5/application/vnd.apple.mpegurl",
              ],
            ],
          ],
        },
        loop: false,
        controls: true,
        fillSpace: true,
        muted: true,
        skin: "dev",
        // MistVideoObject: mistPlayerRef.current,
      });
    }
    // Load meta player code from chosen edge node
    if (!window.mistplayers) {
      var p = document.createElement("script");
      console.log("Loading new MistServer player");
      p.src = playerUri;
      document.head.appendChild(p);
      p.onload = playStream;
    } else {
      playStream();
    }

    // Destroy the player ref if the component is no longer rendered
    // return () => {
    //   console.log("Destructing MistMetaPlayer");
    //   console.log(MistVideoObject.current);
    //   if (MistVideoObject.current && "reference" in MistVideoObject.current) {
    //     MistVideoObject.current.reference.unload();
    //   }
    // };
  }, []);

  if (useIFrame) {
    return (
      <iframe
        className="fillRow"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          height: "100%",
        }}
        src={htmlUri}
      ></iframe>
    );
  }

  return (
    <div
      id="mistplayer"
      className="fillRow"
      style={{
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        height: "100%",
      }}
    >
      <noscript>
        <a href={htmlUri} target="_blank">
          Click here to play this video
        </a>
      </noscript>
    </div>
  );
};

export default MistPlayer;
