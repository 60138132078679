/*

Loads the stream crafter

*/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useLoadBalancer from "../hooks/MistLoadBalancer";
import "../style.css";
import { StreamCrafter } from '@optimist-video/streamcrafter'
import '@optimist-video/streamcrafter/dist/index.css'

// Hardcoded stream name as configured in MistServer
// used as the base for wildcard streams
const baseStream = "live";
const Stream = () => {
  const [whip, setWHIP] = useState("");
  // Get stream id from url
  let { streamid } = useParams();
  const streamName = baseStream + "+" + streamid;
  // Get ingest node and stats
  const [getNode] = useLoadBalancer({
    streamName,
  });

  useEffect(() => {
    const getHost = async () => {
      const newHost = await getNode();
      if (newHost == "") {
        console.log("Finding new ingest point in 5 seconds...");
        setInterval(getHost, 5000);
        return;
      }
      console.log("Found ingest point " + newHost);
      setWHIP("https://" + newHost + "/view/webrtc/" + streamName);
    };
    // When the page is being served over HTTP, assume we are in dev mode and
    // init target to a local media server
    if (window.location.protocol == "http:") {
      setWHIP("http://localhost:8080/webrtc/" + streamName);
    } else {
      // Else call MistLoadBalancer to get the closes ingest point
      getHost();
    }
  }, []);

  return (
    <div className="container">
      <StreamCrafter
        ingestUri={whip}
        sceneWidth={1280}
        sceneHeight={720}
      />
    </div>
  );
};

export default Stream;
