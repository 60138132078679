/*

Redirects the user to a random stream page

*/

import React from "react";
import { Navigate } from "react-router-dom";

const NewStream = () => {
  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const url = "/stream/" + makeid(8);
  return <Navigate push to={url} />;
};

export default NewStream;
