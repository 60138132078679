import React, { useRef } from "react";
import "../../style.css";
import useWebRTCPlayer from "./hooks/WebRTCPlayer";

const CanvasPlayer = (props) => {
  const canvasRef = useRef(null);
  useWebRTCPlayer({
    canvasRef: canvasRef,
    streamBitrate: 1000000,
    uri: props.uri
  });

  return (
    <video
      className="fillRow"
      style={{ width: "100%", maxWidth: "100%", height: "100%", height: "100%" }}
      autoPlay={true}
      muted="muted"
      controls
      playsInline
      ref={canvasRef}
    />
  );
};

export default CanvasPlayer;
