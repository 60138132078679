import * as React from "react";
import NewStream from "./pages/NewStream.js";
import Stream from "./pages/Stream.js";
import View from "./pages/View.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Routes>
        {/* Stream into a specific stream ID */}
        <Route exact path="/stream/:streamid" element={<Stream />} />
        {/* View the stream with this stream ID */}
        <Route exact path="/view/:streamid" element={<View />} />
        {/* Redirect to a random /stream/ page */}
        <Route exact path="/stream" element={<NewStream />} />
        <Route path="/" element={<NewStream />} />
      </Routes>
    </Router>
  );
}
